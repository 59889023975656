
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
import _ from "lodash";
import helpers from "@/helpers/global";
// let loadingEl: any;

export default defineComponent({
  name: "SetupAcceptableBehaviors",
  created() {
    this.$store.dispatch("user/loadCurrentUser");
  },
  watch: {
    step(value) {
      if (value) {
        window.scrollTo(0, 0);
      }
    },
    error(error) {
      if (error) {
        ElMessage.error(error);
        setTimeout(() => {
          this.$router.push({ name: "pageNotFound404" });
        }, 3000);
      }
    },
    // loading(value) {
    //   if (value) {
    //     loadingEl = ElLoading.service({
    //       lock: true,
    //       text: "Login..",
    //       background: "#ffffff90",
    //     });
    //   } else {
    //     if (loadingEl) {
    //       loadingEl.close();
    //     }
    //   }
    // },
    OAIC_dominant_characteristics: {
      handler(newValue) {
        if (newValue.personal_place) {
          this.personal_placeSlice(parseInt(newValue.personal_place));
        }
        if (newValue.dynamic_entrepreneurial_place) {
          this.dynamic_entrepreneurial_placeSlice(
            parseInt(newValue.dynamic_entrepreneurial_place)
          );
        }
        if (newValue.results_oriented) {
          this.results_orientedSlice(parseInt(newValue.results_oriented));
        }
        if (newValue.controlled_and_structured) {
          this.controlled_and_structuredSlice(
            parseInt(newValue.controlled_and_structured)
          );
        }
      },
      deep: true,
    },
    OAIC_organizational_leadership: {
      handler(newValue) {
        if (newValue.mentoring) {
          this.mentoringSlice(parseInt(newValue.mentoring));
        }
        if (newValue.entrepreneurship) {
          this.entrepreneurshipSlice(parseInt(newValue.entrepreneurship));
        }
        if (newValue.no_nonsense) {
          this.no_nonsenseSlice(parseInt(newValue.no_nonsense));
        }
        if (newValue.coordinating) {
          this.coordinatingSlice(parseInt(newValue.coordinating));
        }
      },
      deep: true,
    },
    OAIC_management_of_employees: {
      handler(newValue) {
        if (newValue.teamwork) {
          this.teamworkSlice(parseInt(newValue.teamwork));
        }
        if (newValue.individual_risk_taking) {
          this.individual_risk_takingSlice(
            parseInt(newValue.individual_risk_taking)
          );
        }
        if (newValue.hard_driving) {
          this.hard_drivingSlice(parseInt(newValue.hard_driving));
        }
        if (newValue.security) {
          this.securitySlice(parseInt(newValue.security));
        }
      },
      deep: true,
    },
    OAIC_organization_glue: {
      handler(newValue) {
        if (newValue.loyalty) {
          this.loyaltySlice(parseInt(newValue.loyalty));
        }
        if (newValue.commitment) {
          this.commitmentSlice(parseInt(newValue.commitment));
        }
        if (newValue.emphasis) {
          this.emphasisSlice(parseInt(newValue.emphasis));
        }
        if (newValue.formal_rules) {
          this.formal_rulesSlice(parseInt(newValue.formal_rules));
        }
      },
      deep: true,
    },
    OAIC_strategic_emphases: {
      handler(newValue) {
        if (newValue.human_development) {
          this.human_developmentSlice(parseInt(newValue.human_development));
        }
        if (newValue.resources_and_challenges) {
          this.resources_and_challengesSlice(
            parseInt(newValue.resources_and_challenges)
          );
        }
        if (newValue.achievement) {
          this.achievementSlice(parseInt(newValue.achievement));
        }
        if (newValue.stability) {
          this.stabilitySlice(parseInt(newValue.stability));
        }
      },
      deep: true,
    },
    OAIC_criteria_of_success: {
      handler(newValue) {
        if (newValue.human_resources) {
          this.human_resourcesSlice(parseInt(newValue.human_resources));
        }
        if (newValue.most_unique) {
          this.most_uniqueSlice(parseInt(newValue.most_unique));
        }
        if (newValue.winning_in_the_marketplace) {
          this.winning_in_the_marketplaceSlice(
            parseInt(newValue.winning_in_the_marketplace)
          );
        }
        if (newValue.efficiency) {
          this.efficiencySlice(parseInt(newValue.efficiency));
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      step: "get_start", // get_start
      loaded: false,
      getStartedButtonLoading: false,
      answersGroupMap: {},
      OAIC_dominant_characteristicsMax: 100,
      OAIC_organizational_leadershipMax: 100,
      OAIC_management_of_employeesMax: 100,
      OAIC_organization_glueMax: 100,
      OAIC_strategic_emphasesMax: 100,
      OAIC_criteria_of_successMax: 100,
      // get_start
      // OAIC_Archetypes : deleted
      // OAIC_archetypes_1_6
      // OAIC_archetypes_2_6
      // OAIC_archetypes_3_6
      // OAIC_archetypes_4_6
      // OAIC_archetypes_5_6
      // OAIC_archetypes_6_6
      // setup_complete
      cloneOldSetUp: "",
      oldSetUpData: [
        {
          id: "111",
          label: "Culture Fit for Jan 2022 Batch",
        },
        {
          id: "222",
          label: "Culture Fit for feb 2022 Batch",
        },
        {
          id: "333",
          label: "Culture Fit for mar 2022 Batch",
        },
      ],
      selectedExistsTemplate: "",
      scenarioIndexShowing: 1,
      acceptableBehaviorsScenario: [
        {
          id: "1_late_to_work",
          label: "Regularly showing up 5-min late to work",
          value: "",
          index: 1,
        },
        {
          id: "2_Unresponsive_over_chat",
          label:
            "Unresponsive over chat for most of the day without an away status.",
          value: "",
          index: 2,
        },
        {
          id: "3_headphones_and_listening_to_music",
          label: "Using headphones and listening to music while working",
          value: "",
          index: 3,
        },
        {
          id: "4444",
          label: "Aacceptable Behaviors Scenario - 4",
          value: "",
          index: 4,
        },
        {
          id: "5555",
          label: "Aacceptable Behaviors Scenario - 5",
          value: "",
          index: 5,
        },
        {
          id: "66666",
          label: "Aacceptable Behaviors Scenario - 6",
          value: "",
          index: 6,
        },
      ],
      OAIC_dominant_characteristics: {
        personal_place: 0,
        dynamic_entrepreneurial_place: 0,
        results_oriented: 0,
        controlled_and_structured: 0,
      },
      OAIC_organizational_leadership: {
        mentoring: 0,
        entrepreneurship: 0,
        no_nonsense: 0,
        coordinating: 0,
      },
      OAIC_management_of_employees: {
        teamwork: 0,
        individual_risk_taking: 0,
        hard_driving: 0,
        security: 0,
      },
      OAIC_organization_glue: {
        loyalty: 0,
        commitment: 0,
        emphasis: 0,
        formal_rules: 0,
      },
      OAIC_strategic_emphases: {
        human_development: 0,
        resources_and_challenges: 0,
        achievement: 0,
        stability: 0,
      },
      OAIC_criteria_of_success: {
        human_resources: 0,
        most_unique: 0,
        winning_in_the_marketplace: 0,
        efficiency: 0,
      },
      aiSetup: null as any,
    };
  },
  computed: {
    companyLogoUrl() {
      const defaultLogoUrl = "";
      const companyLogoUrl = this.$store.getters["user/companyLogoUrl"];
      return companyLogoUrl || defaultLogoUrl;
    },
    error() {
      return this.$store.getters["assessmentSection/error"];
    },
    loading() {
      return this.$store.getters["assessmentSection/loading"];
    },
    oaicArchetypes() {
      return this.$store.getters["assessmentSection/oaicArchetypes"];
    },
    selectScenario() {
      let value = true;
      const scenario = this.acceptableBehaviorsScenario;
      if (scenario[this.scenarioIndexShowing - 1].value) {
        value = false;
      }
      return value;
    },
    formId() {
      return this.$store.getters["assessmentTemplate/formId"];
    },
    values() {
      return this.$store.getters["assessmentTemplate/values"];
    },
    existsTemplates() {
      return this.$store.getters["assessmentTemplate/existsTemplates"];
    },
    // OAIC_dominant_characteristicsMax() {
    //   let value = 100;
    //   value = value - this.OAIC_dominant_characteristics.personal_place;
    //   value =
    //     value -
    //     this.OAIC_dominant_characteristics.dynamic_entrepreneurial_place;
    //   value = value - this.OAIC_dominant_characteristics.results_oriented;
    //   value =
    //     value - this.OAIC_dominant_characteristics.controlled_and_structured;
    //   return value;
    // },
    // OAIC_organizational_leadershipMax() {
    //   let value = 100;
    //   value = value - this.OAIC_organizational_leadership.mentoring;
    //   value = value - this.OAIC_organizational_leadership.entrepreneurship;
    //   value = value - this.OAIC_organizational_leadership.no_nonsense;
    //   value = value - this.OAIC_organizational_leadership.coordinating;
    //   return value;
    // },
    // OAIC_management_of_employeesMax() {
    //   let value = 100;
    //   value = value - this.OAIC_management_of_employees.teamwork;
    //   value = value - this.OAIC_management_of_employees.individual_risk_taking;
    //   value = value - this.OAIC_management_of_employees.hard_driving;
    //   value = value - this.OAIC_management_of_employees.security;
    //   return value;
    // },
    // OAIC_organization_glueMax() {
    //   let value = 100;
    //   value = value - this.OAIC_organization_glue.loyalty;
    //   value = value - this.OAIC_organization_glue.commitment;
    //   value = value - this.OAIC_organization_glue.emphasis;
    //   value = value - this.OAIC_organization_glue.formal_rules;
    //   return value;
    // },
    // OAIC_strategic_emphasesMax() {
    //   let value = 100;
    //   value = value - this.OAIC_strategic_emphases.human_development;
    //   value = value - this.OAIC_strategic_emphases.resources_and_challenges;
    //   value = value - this.OAIC_strategic_emphases.achievement;
    //   value = value - this.OAIC_strategic_emphases.stability;
    //   return value;
    // },
    // OAIC_criteria_of_successMax() {
    //   let value = 100;
    //   value = value - this.OAIC_criteria_of_success.human_resources;
    //   value = value - this.OAIC_criteria_of_success.most_unique;
    //   value = value - this.OAIC_criteria_of_success.winning_in_the_marketplace;
    //   value = value - this.OAIC_criteria_of_success.efficiency;
    //   return value;
    // },
    nextBTN_OAIC_dominantValidate() {
      if (
        this.OAIC_dominant_characteristicsMax == 0 &&
        this.step == "OAIC_archetypes_1_6"
      ) {
        return false;
      } else if (
        this.OAIC_organizational_leadershipMax == 0 &&
        this.step == "OAIC_archetypes_2_6"
      ) {
        return false;
      } else if (
        this.OAIC_management_of_employeesMax == 0 &&
        this.step == "OAIC_archetypes_3_6"
      ) {
        return false;
      } else if (
        this.OAIC_organization_glueMax == 0 &&
        this.step == "OAIC_archetypes_4_6"
      ) {
        return false;
      } else if (
        this.OAIC_strategic_emphasesMax == 0 &&
        this.step == "OAIC_archetypes_5_6"
      ) {
        return false;
      } else if (
        this.OAIC_criteria_of_successMax == 0 &&
        this.step == "OAIC_archetypes_6_6"
      ) {
        return false;
      }
      return true;
    },
    isLightColor(): any {
      const user = this.$store.getters["user/user"] || {};
      const color = user.color || "#000";
      return helpers.isLightColor(color);
    },
  },
  mounted() {
    const formId = this.$router.currentRoute.value.query.id;
    this.checkQuestionId();
    this.load(formId);
    this.$store.dispatch("forceReady", null, { root: true });
  },
  methods: {
    async getStarted() {
      await this.useSelectedExistsTemplate();
      this.step = "OAIC_archetypes_1_6";
      this.startSetupValues();
    },
    async useSelectedExistsTemplate() {
      if (this.selectedExistsTemplate) {
        const selectedExistsTemplate = _.find(this.existsTemplates, {
          _id: this.selectedExistsTemplate,
        });
        if (selectedExistsTemplate) {
          this.getStartedButtonLoading = true;
          const formId = this.$router.currentRoute.value.query.id;
          await this.$store.dispatch(
            "assessmentTemplate/loadFromExistsTemplate",
            {
              formId: formId,
              selectedExistsTemplate: selectedExistsTemplate,
              section: "OAIC_archetypes",
            },
            {
              root: true,
            }
          );
          this.getStartedButtonLoading = false;
        }
      }
    },
    async startSetupValues() {
      // console.log("startSetupValues()");
      const aiSetup = this.aiSetup;
      // console.log(aiSetup, "aiSetup");

      this.loaded = false;
      this.step = "OAIC_archetypes_1_6";
      await this.$store.dispatch(
        "assessmentSection/loadSetupOaicArchetypes",
        null,
        {
          root: true,
        }
      );
      // init default values for edit state
      // console.log(this.oaicArchetypes, "this.oaicArchetypes");
      // console.log(this.values, "this.values");
      if (this.oaicArchetypes) {
        const hasOwnProperty = Object.prototype.hasOwnProperty;
        if (
          (this.values &&
            hasOwnProperty.call(this.values, "oaic_archetypes")) ||
          aiSetup
        ) {
          // TODO: Need to know dimension and ID. Not only map ID and value
          // history
          //   id => value
          // AI
          //   dimension => value

          try {
            for (const key in this.oaicArchetypes.scenarios) {
              const scenario = this.oaicArchetypes.scenarios[key];
              const scenarioKey = _.snakeCase(key);
              for (const i in scenario.questions) {
                const question = scenario.questions[i];
                const questionId = question.id;
                if (aiSetup) {
                  let profile = question.profile;

                  // @fixed alias key
                  if (profile == "clan") profile = "collaboration";
                  if (profile == "create") profile = "creation";
                  if (profile == "complete") profile = "competition";

                  const path = `oaic_archetypes.${scenarioKey}.${profile}`;
                  const value = _.get(aiSetup, path);
                  // console.log(scenarioKey, questionId, profile, value);
                  if (value) {
                    question.value = value;
                  } else {
                    console.error("not found value map from AI");
                    console.log(scenarioKey, questionId, profile, value);
                  }
                } else {
                  const history = this.values.oaic_archetypes;
                  const hasScenario = hasOwnProperty.call(history, scenarioKey);
                  if (hasScenario) {
                    const hasValue = hasOwnProperty.call(
                      history[scenarioKey],
                      questionId
                    );
                    if (hasValue) {
                      const value = history[scenarioKey][questionId];
                      question.value = value;
                    }
                  }
                }
              }
            }
            this.OAIC_dominant_characteristicsMax = 0;
            this.OAIC_organizational_leadershipMax = 0;
            this.OAIC_management_of_employeesMax = 0;
            this.OAIC_organization_glueMax = 0;
            this.OAIC_strategic_emphasesMax = 0;
            this.OAIC_criteria_of_successMax = 0;
          } catch (e) {
            console.error(e);
          }
        }
      }
      this.loaded = true;
    },
    async load(formId: any) {
      await this.$store.dispatch(
        "assessmentTemplate/load",
        { formId: formId, section: "OAIC_archetypes" },
        { root: true }
      );

      // try to skip first step and auto set with ai
      if (this.$store.getters["assessmentTemplate/values"]) {
        const values = this.$store.getters["assessmentTemplate/values"];
        const path = "ai_setup.oaic_archetypes";
        const aiSetup = _.get(values, path);
        // console.log(aiSetup, "aiSetup");
        if (aiSetup && aiSetup.pending_review) {
          this.aiSetup = aiSetup;
          this.startSetupValues();
        }
      }
    },
    onChangeWeight($event: any, path: string, question: any) {
      let value = parseInt($event.target.value);
      if (isNaN(value)) {
        value = 0;
      }
      if (value > 99) {
        value = 100;
      }
      $event.target.value = value;
      question.value = value;
      question.dirty = true;

      let totalValue = 0;
      for (const i in this.oaicArchetypes.scenarios[path].questions) {
        if (question.id == this.oaicArchetypes.scenarios[path].questions[i].id)
          continue;
        const q = this.oaicArchetypes.scenarios[path].questions[i];
        totalValue += q.value;
      }

      // auto fill sibling inputs (average percent input)
      const totalQuestions =
        this.oaicArchetypes.scenarios[path].questions.length;
      let totalDirty = 0;
      totalValue += question.value;
      for (const i in this.oaicArchetypes.scenarios[path].questions) {
        if (question.id == this.oaicArchetypes.scenarios[path].questions[i].id)
          continue;
        const isDirty = this.oaicArchetypes.scenarios[path].questions[i].dirty;
        if (!isDirty) {
          totalDirty++;
          totalValue += parseInt(
            this.oaicArchetypes.scenarios[path].questions[i].value
          );
        }
      }

      // dirty all sibling inputs if full score
      if (totalValue == 100) {
        if (totalDirty < totalQuestions) {
          for (const i in this.oaicArchetypes.scenarios[path].questions) {
            if (!this.oaicArchetypes.scenarios[path].questions[i].dirty) {
              this.oaicArchetypes.scenarios[path].questions[i].dirty = true;
            }
          }
        }
      }

      // auto trig to next button with original frontend logic
      // @see nextBTN_OAIC_dominantValidate()
      // 1: dominantCharacteristics
      // 2: organizationalLeadership
      // 3: managementOfEmployees
      // 4: organizationGlue
      // 5: strategicEmphases
      // 6: criteriaOfSuccess
      const groupMaxValue = 100 - totalValue;
      const order = parseInt(
        this.step.split("OAIC_archetypes_")[1].split("_")[0]
      );

      switch (String(order)) {
        case "1":
          this.OAIC_dominant_characteristicsMax = groupMaxValue;
          break;
        case "2":
          this.OAIC_organizational_leadershipMax = groupMaxValue;
          break;
        case "3":
          this.OAIC_management_of_employeesMax = groupMaxValue;
          break;
        case "4":
          this.OAIC_organization_glueMax = groupMaxValue;
          break;
        case "5":
          this.OAIC_strategic_emphasesMax = groupMaxValue;
          break;
        case "6":
          this.OAIC_criteria_of_successMax = groupMaxValue;
          break;
      }
    },
    validateGroupValue(path: string) {
      const totalQuestions =
        this.oaicArchetypes.scenarios[path].questions.length;
      let totalDirty = 0;
      let totalValue = 0;
      let valid = true;
      for (const i in this.oaicArchetypes.scenarios[path].questions) {
        if (this.oaicArchetypes.scenarios[path].questions[i].dirty) {
          totalDirty++;
          totalValue += parseInt(
            this.oaicArchetypes.scenarios[path].questions[i].value
          );
        }
      }
      if (totalDirty == totalQuestions) {
        valid = totalValue == 100;
      }
      return valid;
    },
    checkQuestionId() {
      const id = this.$router.currentRoute.value.query.id;
      if (!id) {
        ElMessage.error((this as any).$t("popup.message.message_16e"));
        setTimeout(() => {
          this.$router.push({ name: "pageNotFound404" });
        }, 1000);
      }
    },
    closeTab() {
      window.close();
    },
    setupPreviousStep() {
      const index = this.scenarioIndexShowing;
      if (index == 2) {
        this.step = "OAIC_archetypes_1_6";
      } else {
        this.scenarioIndexShowing = index - 1;
      }
    },
    setupNexStep() {
      const scenario = this.acceptableBehaviorsScenario;
      if (!this.selectScenario && scenario[this.scenarioIndexShowing]) {
        this.scenarioIndexShowing = this.scenarioIndexShowing + 1;
      } else if (!this.selectScenario) {
        this.step = "setup_complete";
      }
    },
    // OAIC_criteria_of_success
    human_resourcesSlice(value: number) {
      if (value > 99) {
        this.OAIC_criteria_of_success.human_resources = 100;
      } else {
        this.OAIC_criteria_of_success.human_resources = value;
      }
    },
    most_uniqueSlice(value: number) {
      if (value > 99) {
        this.OAIC_criteria_of_success.most_unique = 100;
      } else {
        this.OAIC_criteria_of_success.most_unique = value;
      }
    },
    winning_in_the_marketplaceSlice(value: number) {
      if (value > 99) {
        this.OAIC_criteria_of_success.winning_in_the_marketplace = 100;
      } else {
        this.OAIC_criteria_of_success.winning_in_the_marketplace = value;
      }
    },
    efficiencySlice(value: number) {
      if (value > 99) {
        this.OAIC_criteria_of_success.efficiency = 100;
      } else {
        this.OAIC_criteria_of_success.efficiency = value;
      }
    },
    // OAIC_strategic_emphases
    human_developmentSlice(value: number) {
      if (value > 99) {
        this.OAIC_strategic_emphases.human_development = 100;
      } else {
        this.OAIC_strategic_emphases.human_development = value;
      }
    },
    resources_and_challengesSlice(value: number) {
      if (value > 99) {
        this.OAIC_strategic_emphases.resources_and_challenges = 100;
      } else {
        this.OAIC_strategic_emphases.resources_and_challenges = value;
      }
    },
    achievementSlice(value: number) {
      if (value > 99) {
        this.OAIC_strategic_emphases.achievement = 100;
      } else {
        this.OAIC_strategic_emphases.achievement = value;
      }
    },
    stabilitySlice(value: number) {
      if (value > 99) {
        this.OAIC_strategic_emphases.stability = 100;
      } else {
        this.OAIC_strategic_emphases.stability = value;
      }
    },
    // OAIC_organization_glue
    loyaltySlice(value: number) {
      if (value > 99) {
        this.OAIC_organization_glue.loyalty = 100;
      } else {
        this.OAIC_organization_glue.loyalty = value;
      }
    },
    commitmentSlice(value: number) {
      if (value > 99) {
        this.OAIC_organization_glue.commitment = 100;
      } else {
        this.OAIC_organization_glue.commitment = value;
      }
    },
    emphasisSlice(value: number) {
      if (value > 99) {
        this.OAIC_organization_glue.emphasis = 100;
      } else {
        this.OAIC_organization_glue.emphasis = value;
      }
    },
    formal_rulesSlice(value: number) {
      if (value > 99) {
        this.OAIC_organization_glue.formal_rules = 100;
      } else {
        this.OAIC_organization_glue.formal_rules = value;
      }
    },
    // OAIC_management_of_employees
    teamworkSlice(value: number) {
      if (value > 99) {
        this.OAIC_management_of_employees.teamwork = 100;
      } else {
        this.OAIC_management_of_employees.teamwork = value;
      }
    },
    individual_risk_takingSlice(value: number) {
      if (value > 99) {
        this.OAIC_management_of_employees.individual_risk_taking = 100;
      } else {
        this.OAIC_management_of_employees.individual_risk_taking = value;
      }
    },
    hard_drivingSlice(value: number) {
      if (value > 99) {
        this.OAIC_management_of_employees.hard_driving = 100;
      } else {
        this.OAIC_management_of_employees.hard_driving = value;
      }
    },
    securitySlice(value: number) {
      if (value > 99) {
        this.OAIC_management_of_employees.security = 100;
      } else {
        this.OAIC_management_of_employees.security = value;
      }
    },
    // OAIC_organizational_leadership
    mentoringSlice(value: number) {
      if (value > 99) {
        this.OAIC_organizational_leadership.mentoring = 100;
      } else {
        this.OAIC_organizational_leadership.mentoring = value;
      }
    },
    entrepreneurshipSlice(value: number) {
      if (value > 99) {
        this.OAIC_organizational_leadership.entrepreneurship = 100;
      } else {
        this.OAIC_organizational_leadership.entrepreneurship = value;
      }
    },
    no_nonsenseSlice(value: number) {
      if (value > 99) {
        this.OAIC_organizational_leadership.no_nonsense = 100;
      } else {
        this.OAIC_organizational_leadership.no_nonsense = value;
      }
    },
    coordinatingSlice(value: number) {
      if (value > 99) {
        this.OAIC_organizational_leadership.coordinating = 100;
      } else {
        this.OAIC_organizational_leadership.coordinating = value;
      }
    },
    // OAIC_dominant_characteristics
    personal_placeSlice(value: number) {
      if (value > 99) {
        this.OAIC_dominant_characteristics.personal_place = 100;
      } else {
        this.OAIC_dominant_characteristics.personal_place = value;
      }
    },
    dynamic_entrepreneurial_placeSlice(value: number) {
      if (value > 99) {
        this.OAIC_dominant_characteristics.dynamic_entrepreneurial_place = 100;
      } else {
        this.OAIC_dominant_characteristics.dynamic_entrepreneurial_place =
          value;
      }
    },
    results_orientedSlice(value: number) {
      if (value > 99) {
        this.OAIC_dominant_characteristics.results_oriented = 100;
      } else {
        this.OAIC_dominant_characteristics.results_oriented = value;
      }
    },
    controlled_and_structuredSlice(value: number) {
      if (value > 99) {
        this.OAIC_dominant_characteristics.controlled_and_structured = 100;
      } else {
        this.OAIC_dominant_characteristics.controlled_and_structured = value;
      }
    },
    nextBTN_OAIC_dominant() {
      if (this.step == "OAIC_Archetypes") {
        this.step = "OAIC_archetypes_1_6";
      } else if (
        this.OAIC_dominant_characteristicsMax == 0 &&
        this.step == "OAIC_archetypes_1_6"
      ) {
        this.step = "OAIC_archetypes_2_6";
      } else if (
        this.OAIC_organizational_leadershipMax == 0 &&
        this.step == "OAIC_archetypes_2_6"
      ) {
        this.step = "OAIC_archetypes_3_6";
      } else if (
        this.OAIC_management_of_employeesMax == 0 &&
        this.step == "OAIC_archetypes_3_6"
      ) {
        this.step = "OAIC_archetypes_4_6";
      } else if (
        this.OAIC_organization_glueMax == 0 &&
        this.step == "OAIC_archetypes_4_6"
      ) {
        this.step = "OAIC_archetypes_5_6";
      } else if (
        this.OAIC_strategic_emphasesMax == 0 &&
        this.step == "OAIC_archetypes_5_6"
      ) {
        this.step = "OAIC_archetypes_6_6";
      } else if (
        this.OAIC_criteria_of_successMax == 0 &&
        this.step == "OAIC_archetypes_6_6"
      ) {
        this.finishOAIC();
      }
    },
    finishOAIC() {
      const answers = [];
      for (const i in this.oaicArchetypes.scenarios) {
        for (const j in this.oaicArchetypes.scenarios[i].questions) {
          const question = this.oaicArchetypes.scenarios[i].questions[j];
          const answer = { id: question.id, value: parseInt(question.value) };
          answers.push(answer);
        }
      }
      this.$store.commit("assessmentSection/loading", true);
      const sectionAnswersDto = {
        _id: this.$router.currentRoute.value.query.id,
        section: this.oaicArchetypes,
        answers: answers,
        callback: () => {
          this.$store.commit("assessmentSection/loading", false);
          this.step = "setup_complete";
        },
      };
      this.$store.dispatch(
        "assessmentTemplate/saveSectionAnswers",
        sectionAnswersDto,
        {
          root: true,
        }
      );

      if (this.aiSetup) {
        this.$store.dispatch("aiAssistedSetup/unsetup", "culture_profile", {
          root: true,
        });
      }
    },
    backBTN_OAIC_dominant() {
      if (this.step == "OAIC_archetypes_2_6") {
        this.step = "OAIC_archetypes_1_6";
      } else if (this.step == "OAIC_archetypes_3_6") {
        this.step = "OAIC_archetypes_2_6";
      } else if (this.step == "OAIC_archetypes_4_6") {
        this.step = "OAIC_archetypes_3_6";
      } else if (this.step == "OAIC_archetypes_5_6") {
        this.step = "OAIC_archetypes_4_6";
      } else if (this.step == "OAIC_archetypes_6_6") {
        this.step = "OAIC_archetypes_5_6";
      }
    },
    // @todo I'll refactor this after launch beta version (common function)
    lang(obj: any, attr: string) {
      // @note hold all this issue waiting all translation
      const lang = (this as any).$i18n.getLocale(0) || "en";
      let label = "";
      if (_.isObject(obj)) {
        if (lang != "en") {
          attr = attr + "_" + lang;
        }
        if (Object.prototype.hasOwnProperty.call(obj, attr)) {
          label = (obj as any)[attr];
        }
      }
      return label;
    },
  },
});
